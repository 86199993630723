<template>
  <div>
    <b-row class="mb-3">
      <b-col md="7" class="px-4">
        <h1 class="mb-3">Company Overview</h1>

        <p class="w-75 text-lg">
          At Pentecost Job Search, we are passionate about connecting job seekers with meaningful employment opportunities. 
          Our app is designed to simplify the job search process, making it easier for individuals to find their dream jobs and for employers to discover top talent.
          <br /> <br />With a deep understanding of the challenges faced by job seekers and the evolving needs of employers, we have developed an innovative platform that leverages cutting-edge technology to bridge the gap between job seekers and employers. 
          <br /> <br />Our user-friendly interface and advanced features provide a seamless experience for both job seekers and employers, ensuring efficient and effective recruitment processes.
        </p>
      </b-col>

      <b-col md="5">
        <div class="img-container">
          <b-img src="@/assets/images/public/img-2.jpg"></b-img>
        </div>
      </b-col>
    </b-row>

    <b-row class="bg-primary p-2 mb-3">
      <b-col cols="12 mb-2">
        <p class="text-yellow text-left w-75 text-lg">Our mission is to empower individuals in their career journeys, enabling them to discover, apply for, and secure job opportunities that align with their skills, interests, and aspirations. We believe that meaningful employment not only transforms lives but also strengthens communities and contributes to economic growth.</p>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col md="7" class="px-4">
        <h2>Key Features</h2>
        <ol>
          <li>Job Search: Our app offers a comprehensive job search feature, allowing users to explore a wide range of job openings across various industries and locations. Users can search by keywords, industry, location, and other relevant filters to find the most relevant job listings.</li>
          <li>Profile Creation: Job seekers can create detailed profiles that highlight their skills, qualifications, and work experience. They can also upload resumes and other supporting documents to showcase their expertise to potential employers.</li>
          <li>Application Tracking: The app provides a streamlined application tracking system, enabling job seekers to manage their applications, track the status of their submissions, and receive updates on their progress.</li>
          <li>Employer Engagement: We actively partner with employers to ensure a robust and diverse pool of job opportunities on our platform. Employers can post job listings, review applications, and connect with potential candidates directly through the app.</li>
          <li>Notifications and Alerts: Job seekers can set up personalized notifications and alerts to stay informed about new job openings, application status updates, and other relevant information.</li>
          <li>Resources and Career Guidance: We provide valuable resources, career tips, and guidance to support job seekers in their professional development and empower them to make informed career decisions.</li>
        </ol>

      
        <p class="text-lg my-3">
          At Pentecost Job Search, we are committed to fostering a thriving ecosystem that brings job seekers and employers together. We strive to deliver exceptional user experiences, facilitate meaningful connections, and contribute to the growth and prosperity of individuals and businesses alike.
        </p>
      </b-col>

      <b-col md="5">
        <div class="img-container">
          <b-img src="@/assets/images/public/img-1.jpg"></b-img>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BImg,
} from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 1.5rem;
  font-weight: bold;
}

.text-yellow {
  color: yellow;
}
.text-lg {
  font-size: 14px;
}
.img-container {
  height: 400px;
  widows: 300px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

ol {
  font-size: 15px;
  li {
    margin-bottom: 10px;
  }
}
</style>
